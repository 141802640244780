import React from "react";

export default ({ color }) => (
  <svg viewBox="0 0 27 24">
    <path
      fill={color}
      d="M16.3,1.6c-0.6-1-1.6-1.6-2.8-1.6s-2.1,0.6-2.7,1.6L0.4,19.3c-0.6,1-0.6,2.1,0,3.1C1,23.4,2,24,3.1,24h20.7
	c1.2,0,2.1-0.6,2.7-1.6c0.6-1,0.6-2.1,0-3.1L16.3,1.6z M25.4,21.8c-0.1,0.2-0.6,0.9-1.5,0.9H3.1c-0.9,0-1.4-0.7-1.5-0.9
	c-0.1-0.2-0.4-1,0-1.7L12,2.2c0.4-0.8,1.2-0.9,1.5-0.9s1,0.1,1.5,0.9L25.5,20C25.8,20.8,25.5,21.5,25.4,21.8z M13.7,7.4
	c-0.4,0-0.7,0.3-0.7,0.7v6.3c0,0.4,0.3,0.7,0.7,0.7c0.4,0,0.7-0.3,0.7-0.7V8.1C14.4,7.8,14.1,7.4,13.7,7.4z M13.7,17.4
	c-0.5,0-0.9,0.4-0.9,0.9c0,0.5,0.4,0.9,0.9,0.9s0.9-0.4,0.9-0.9C14.6,17.8,14.2,17.4,13.7,17.4z"
    />
  </svg>
);
